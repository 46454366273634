import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import {
  Title,
  Text,
  Scrollable,
  HorizontalScrollingLoop,
  Hero,
  SEO,
  TShapedBlock,
  SplitTextBlock,
  Button,
  SecretsBlock,
  ImageBlock,
} from 'components';
import img38 from '../images/museo/38-lucchesi-museo.jpg';
import img39 from '../images/museo/39-lucchesi-museo.jpg';
import img40 from '../images/museo/40-lucchesi-museo.jpg';
import img41 from '../images/museo/41-lucchesi-museo.jpg';
import img42 from '../images/museo/42-lucchesi-museo.jpg';
import img43 from '../images/museo/43-lucchesi-museo.jpg';
import img44 from '../images/museo/44-lucchesi-museo.jpg';
import img45 from '../images/museo/45-lucchesi-museo.jpg';
import img46 from '../images/museo/46-lucchesi-museo.jpg';
import img47 from '../images/museo/47-lucchesi-museo.jpg';
import img48 from '../images/museo/48-lucchesi-museo.jpg';
import img49 from '../images/museo/49-lucchesi-museo.jpg';
import img50 from '../images/museo/50-lucchesi-museo.jpg';
import img51 from '../images/museo/51-lucchesi-museo.jpg';
import img52 from '../images/museo/52-lucchesi-museo.jpg';

const InnovazionePage = ({ location }) => {
  const { t } = useTranslation();

  const textileImages = [img38, img39, img40];

  const PAGE_PROPERTIES = {
    title: (
      <Trans>
        Mostra della <font className="text-secondary">moda</font> e del{' '}
        <font className="text-secondary">tessuto contemporaneo</font>
      </Trans>
    ),
  };

  const scrollImages = [img48, img49, img50];

  return (
    <Layout location={location}>
      <SEO
        keywords={['Vivere in tessuto', 'Lucchesi']}
        title={t("Mostra permanente della moda e del tessuto")}
        description={t("Un vero e proprio museo del tessuto all'interno di una azienda innovativa e d'avanguardia")}
        location={location}
      />
      <section className="mt-12 md:mt-40">
        <Hero invert {...PAGE_PROPERTIES}></Hero>
      </section>
      <section className="mb-96">
        <Scrollable fullWidth images={textileImages}></Scrollable>
      </section>
      <section className="my-24 px-12 md:px-60 min-h-[60vh] flex flex-col justify-center items-center relative">
        <div className="z-20 md:pt-0 flex flex-col">
          <div className="my-4">
            <Text>{t('NON SOLO MUSEO')}</Text>
          </div>
          <Title>
            <Trans>
              UNO SPAZIO <font className="text-secondary">DINAMICO</font> DOVE FARE RICERCA E
              TROVARE <font className="text-secondary">NUOVE ISPIRAZIONI</font>
            </Trans>
          </Title>
        </div>

        <div className="absolute w-40 md:w-72 h-auto -bottom-16 md:bottom-0 right-12 z-10">
          <img className="w-full h-full object-cover" src={img42}></img>
        </div>
        <div className="absolute w-72 h-auto -top-4 md:-top-8 left-0 md:left-0 z-10">
          <img className="w-full h-full object-cover" src={img41}></img>
        </div>
      </section>
      <section className="my-12 bg-secondary md:px-12 md:py-20">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/5 self-end p-12 md:p-0 text-center md:text-left">
            <Text>
              {t(
                'Lucchesi è un luogo di memoria, formazione e produzione. Uno spazio dinamico dove fare ricerca e trovare nuove ispirazioni.  Un percorso visionario tra tessuti e filati unici: dei veri e propri capolavori destinati a durare nel tempo.',
              )}{' '}
            </Text>
          </div>
          <div className="flex flex-1  md:px-8">
            <img src={img43} className="object-cover w-full h-[80vh]"></img>
          </div>
        </div>
      </section>
      <section className="my-12">
        <HorizontalScrollingLoop
          firstLine={
            <Trans>
              UN <font className="text-secondary">PERCORSO VISIONARIO</font> TRA TESSUTI E{' '}
              <font className="text-secondary">CAPOLAVORI</font> DESTINATI A DURARE NEL TEMPO
            </Trans>
          }
          secondLine={
            <Trans>
              UN <font className="text-secondary">PERCORSO VISIONARIO</font> TRA TESSUTI E{' '}
              <font className="text-secondary">CAPOLAVORI</font> DESTINATI A DURARE NEL TEMPO
            </Trans>
          }
        />
      </section>
      <section className="my-12">
        <div className="px-0 md:px-40 flex">
          <div className="w-1/4">
            <img src={img45} className="w-full h-[50vh] object-cover"></img>
          </div>
          <div className="w-3/4 pl-4 md:pl-16">
            <img src={img44} className="w-full h-[80vh] object-cover"></img>
          </div>
        </div>
      </section>
      <section className="my-12 md:px-12 text-left">
        <div className="px-12 flex flex-col">
          <div className="my-12 uppercase">
            <Text serif>{t('La sede di Prato')}</Text>
          </div>
          <Title>
            <Trans>
              Vogliamo <font className="text-secondary">aprire le porte</font> del nostro{' '}
              <font className="text-secondary">museo</font> per farvi toccare con mano{' '}
              <font className="text-secondary">la nostra storia</font>
            </Trans>
          </Title>
        </div>
      </section>
      <section className="my-12">
        <img className="w-full h-screen" src={img46}></img>
      </section>
      <section className="my-24 flex flex-col md:flex-row px-12 md:px-0 relative">
        <div className="hidden md:flex md:flex-1">
          <img src={img47} className="w-2/3 h-[70vh] object-cover"></img>
        </div>
        <div className="flex md:hidden flex-1">
          <img src={img47} className="w-full h-full object-cover"></img>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-col pr-0 md:pr-24 my-8">
            <Title>
              <Trans>
                Un secolo di <font className="text-secondary">collezioni</font>
              </Trans>
            </Title>
            <Text>
              {t(
                'Nella nostra sede di Prato, a ridosso delle antiche mura della città, conserviamo oltre 100 anni di collezioni, all’ interno di un archivio privato esclusivo per ubicazione, contenuti, immensa varietà di articoli e capi presenti. ',
              )}{' '}
              <br />
            </Text>
          </div>
          <div className="flex flex-col pr-0 md:pr-24 my-8">
            <Title>
              <Trans>
                Archeologia <font className="text-secondary">industriale</font>
              </Trans>
            </Title>
            <Text>
              {t(
                'Una straordinaria opera di archeologia industriale già visitata da migliaia di persone nell’ottobre del 2019 in occasione delle giornate organizzate dal FAI (Fondo Ambiente Italiano).',
              )}
              <br />
              {t(
                'Nel corso del 2022, aderendo al progetto del TURISMO INDUSTRIALE PRATESE, sarà nuovamente possibile per il pubblico apprezzare quella che è stata definita “L’Eccellenza Lucchesi”. ',
              )}
            </Text>
          </div>
        </div>
      </section>
      <section className="mt-24 mb-72">
        <Scrollable images={scrollImages}></Scrollable>
      </section>
      <section className="my-24">
        <TShapedBlock
          title={t('RICHIEDI INFORMAZIONI PER PRENOTARE VISITE GUIDATE O PER GLI OPEN DAY')}
          firstDesc={t(
            'Ciò che ci contraddistingue è la fusione di creatività e ingegno, nella ricerca di qualcosa di nuovo e raffinato, con il massimo rigore per la produzione e la consegna dei tessuti.',
          )}
          secondDesc={t(
            'Facciamo quello che gli altri non fanno: non perchè siamo i più bravi, ma perchè diamo forma a qualcosa che potrete trovare solo da noi.',
          )}
          cta={t('contattaci')}
          image={img40}></TShapedBlock>
      </section>

      <section className="my-12 md:px-12 text-left">
        <div className="px-12 flex flex-col">
          <div className="my-12 uppercase">
            <Text serif>{t('La sede di Prato')}</Text>
          </div>
          <Title>
            <Trans>
              IMMERGETEVI ALL’INTERNO DELLA NOSTRA{' '}
              <font className="text-secondary">MOSTRA PERMANENTE</font> NEL CUORE DI PRATO,{' '}
              <font className="text-secondary">LA CITTA’ DEL TESSUTO</font>
            </Trans>
          </Title>
        </div>
      </section>
      <section className="my-12">
        <img className="w-full object-cover h-screen" src={img52}></img>
      </section>
    </Layout>
  );
};

export default InnovazionePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
